@import '../../sass/utils.scss';

.navbar {
    width: 100%;
    height: 80px;
    background-color: #fff;
    box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);
    & h2 {
        padding: 15px 16px;
    }
    & span {
        color: $brand-green;
    }
}