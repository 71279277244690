@import '../../sass/utils.scss';

.archivos {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    &__form {
        padding-top: 50px;
    }
    &__container {
        height: 80%;
        margin: 20px 100px;
        padding: 80px 250px;
        text-align: center;
        border-radius: 10px;
        background-color: #ffff;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
    &__input {
        height: 50px;
        width: 100%;
        margin-top: 20px;
        background-color: rgb(239, 239, 239);
        border: none;
    }
    &__upload {
        width: 100%;
        height: 60px;
        margin-top: 20px;
        background-color: rgb(239, 239, 239);
        border-radius: 10px;
        background-color: $brand-green;
        text-align: center;
        cursor: pointer;
    }
    &__upload p {
        margin-top: -40px;
        padding: 0px 0;
        font-size: 14px;
        font-weight: 600;
    }
    &__file {
        margin-top: -100px;
        width: 100%;
        height: 60px;
        opacity: 0;
     
    }
    &__upload small {
        font-size: 10px;
    }
    &__btn {
        width: 100%;
        height: 50px;
        margin-top: 60px;
    }
    & span {
        color: $brand-green;
    }
    &__container--btn {
        display: flex;
        justify-content: right;
        width: 20%;
    }
}   