@import '../../sass/utils.scss';

.calendario {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: hidden;
 
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    &-form {
        padding: 20px 30px 0;
    }
    &-form .btn {
        width: 100px;
        color: white;
        font-weight: 200px;
    }
    &-span {
        color: $brand-green;
    }
    &-container {
        margin: 0px;
        height: 500px;
    }
   & button {
        height: 40px;
        width: 60px;
        font-family: $title-font;
        font-size: 10px;
        border: none;
        border-radius: 10px;
        color: $brand-purple;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    }
   & button:hover {
  
    }
}

