@import '../../sass/utils.scss';

.notification-card {
    width: 100%;
    height: 130px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);
    & h3 {
        padding: 15px 30px 0;
        font-size: 20px;
        font-weight: 400;
    }
    & small {
        padding: 0 30px;
    }
    &__btn {
        padding: 10px 30px;
    }
}


.notification__btn {
    width: 80px;
    height: 30px;
    background-color: transparent;
    border: 1px solid $brand-green-alt;
    background-color: transparent;
    color: $brand-green-alt;
    box-shadow: none;
}
.notification__btn:hover {
    color: #fff;
    background-color: $brand-green-alt;

}