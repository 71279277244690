@import '../../../sass/utils.scss';

.higiene {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    &__container {
        padding: 50px 30px 0;
    }
    & h3 {
        font-weight: 300;
    }
}