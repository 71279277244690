@import '../../../sass/utils.scss';

.demo {
    height: 210vh;
    padding-left: 250px;
    padding-top: 50px;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: scroll;
        & h2 {
            padding: 50px 30px 0;
            font-size: 30px;
        }
}