@import '../../sass/utils.scss';

.login {
    height: 100vh;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: hidden;
    &__container {
        display: block;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        height: 400px;
        width: 400px;
        padding: 30px;
        text-align: center;
        background-color: #ffff ;
        box-shadow: 1px 4px 8px 1px rgba(0,0,0,0.1);
    }
    &__btn {
        margin-top: 20px;
    }
    &__btn a {
        color: #333;
        font-size: 12px;
        text-decoration: none;
    }
    &__btn h5 {
        margin-top: 10px;
        color: red;
        font-size: 12px;
        text-decoration: none;
        font-weight: 400;
    }
    &__btn a:hover {
        color: $brand-green;
    }
}