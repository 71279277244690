@import '../../sass/utils.scss';

.filter-period {
    width: 320px;
}
.filter-period__container .input, select { 
    display: flex;
    justify-content: flex-start;
}
.input-options {
    height: 30px;
}
.filter-period__btn {
    width: 100px;
    height: 30px;
    margin-left: 20px;
    margin-top: 0px;
}