@import '../../../sass/utils.scss';

.actas {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    }
    & h3 {
        font-size: 20px;
        font-weight: 600;
        cursor: pointer;
    }
    &__container {
        margin: 0 30px 20px;
    }
    &__card {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
    }
}