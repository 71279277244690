@import '../../sass/utils.scss';

.perfil {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
   & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    }
}
.perfil__container {
    margin: 0 150px;
    & .input__container {
        font-family: $title-font;
    }
    input, select {
        margin: 10px 0;
        width: 100%;
        height: 60px;
        padding: 0 12px;
        font-family: $paragraph-font;
        font-size: 16px;
        border: 1px solid rgb(184, 184, 184);
        border-radius: 10px;
        color: $black-color-first;
        background-color: $brand-white;
    }  
    input::placeholder {
        padding: 0 12px;
        font-size: 16px;
        font-family: $paragraph-font;
        color: $black-color-fourth;
    }
 
}

