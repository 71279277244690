@import '../../sass/utils.scss';

.info-card {
    width: 200px;
    height: 150px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);
    & h3 {
        margin-top: 50px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        color: rgb(113, 113, 113);
    }
    & p {
        text-align: center;
        font-size: 12px;
        color: $brand-purple-alt;
    }
}