@import '../../sass/utils.scss';

.soporte {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
   & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    }
    &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: left;
        padding: 0px 30px;
    }
    &__container h3 {
        padding-top: 40px;
        font-size: 30px;
    }
    & .info-card {
        margin-right: 50px;
    }
    
}