@import '../../../sass/utils.scss';

.costos {
    height: 100vh;
    padding-left: 250px;
    padding-top: 50px;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: scroll;
    & h2 {
        padding: 0px 30px 0;
        font-size: 30px;
    }
    &__container {
        padding: 0px 30px;
    }
    &__container h2 {
        padding: 0;
        font-size: 24px;
        font-weight: 600; 
    }
    &__container h4 {
        padding:10px 0px;
        font-size: 20px;
        font-weight: 600; 
    }
    &__card {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
       
    }
    &__graph {
        width: 510px;
        height: 300px;
        padding: 10px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);
    }
    &__graph-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 20px 0px;
    }
}