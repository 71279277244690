@import '../../../sass/utils.scss';

.evento {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: hidden;
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    &__container {
        padding: 0px 250px;
    }
    & input {
        height: 40px;
    }
    &__btn button {
        margin-top: 20px;
        height: 40px;
    }
}