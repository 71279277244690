@import '../../sass/utils.scss';

.analisis {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: scroll;
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    }
    & h3 {
        font-size: 20px;
        font-weight: 600; 
    }

    &__container {
        margin: 0 30px 20px;

    }
    &__search {
        margin-bottom: 20px;
    }
}

table {
    text-align: center;
    width: 100%;
}
th {
    font-size: 16px;
    font-weight: 400;
}
td {
    font-size: 13px;
}

tr:nth-child(even) {
    background-color: #ffff;
    height: 50px;
  }
tr:nth-child(odd) {
    background-color: $brand-white;
    height: 50px;
  }
.tb__container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}
.tb__btn {
    width: 80px;
    height: 30px;
    margin-right: 10px;
    background-color: $brand-purple;
}
.tb__btn--alt {
    width: 80px;
    height: 30px;
    background-color: #F57F79;
}
.tb__btn--alt:hover {
    background-color:   #F57F79;
}