@import '../../sass/utils.scss';

.listas {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: scroll;
   & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    }
   & h4 {
        padding:10px 30px;
        font-size: 16px;
    }
    & p {
        padding: 0px 30px 0; 
    }
    & .filter-period {
        padding-left: 30px;
    }
    &__info {
        padding: 15px 30px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
    }
    &__graph-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
    }
    &__graph {
        width: 510px;
        height: 300px;
        padding: 10px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);
    }
    &__graph table {
    
    }
    &__card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
    
    }
    &__card {
        width: 350px;
        height: 220px;
        padding: 10px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);
    }
    &__graph-title h5 {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
    }
    &__table-container {
        padding: 20px 30px;
    }
    &__table {
        width: 100%;
        height: 400px;
        padding: 10px;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);
    }
    &__table-container h3 {
        margin-bottom: 5px;
        font-weight: 400;
    }
}
