@import '../../sass/utils.scss';

.sidebar {
    position: fixed;
        left: 0;
    width: 250px;
    height: 100%;
    overflow: scroll;
    background-color: #ffff;
    box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);

    & header {
        margin: 16px;
        line-height: 35px;
        text-align: left;
        font-family: $title-font;
        font-size: 15px;
    }
    &__dot {
        color:$brand-green;
    }
    & h5 {
        font-size: 16px;
        font-weight: 400;
        color: $black-color-fourth;
    }
    & ul a {
        display: block;
        height: 100%;
        width: 100%;
        padding-left: 16px;
        color:$black-color-second;
    }
    & ul a span {
        margin-left: 10px;
    }
}

ul li:hover a {
    width: 100%;
    background-color:$brand-white;
    border-radius: 10px;
}
.sidebar__btn {
    position: absolute;
        bottom: 0;
    width: 200px;
    height: 35px;
    margin-bottom: 15px;
}

.sidebar__sub-menu {
    width: 200px;
    height: 110px;
    padding: 5px;
    border-radius: 10px;
    font-family: $title-font;
    font-size: 12px;
    box-shadow: 1px 4px 8px 1px rgba(17, 17, 19, 0.1);
}
