@import '../../sass/utils.scss';
 
.crear-usuario {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
   & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    }
    &__container {
        padding: 0 250px;
    }
    &__container input {
        height: 40px;
    }
    &__select {    
        margin-top: 20px;
    }
    &__container select {
        height: 40px;
        width: 100%;
    }
}
.profile__btn {
    margin-top: 30px;
    font-size: 14px;
    height: 40px;
}