@import '../../sass/utils.scss';


.servicios {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: scroll;
   & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    }
    &__container {
        padding: 0px 30px;
    }
    &__container h2 {
        padding: 40px 0px;
        font-size: 20px;
    }
    &__container h4 {
        font-size: 16px;
    }
    &__card {
        padding: 15px 0px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    &__graph-container {
        padding-bottom: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }
    &__graph {
        width: 510px;
        height: 300px;
        padding: 10px;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 1px 4px 8px 1px rgba(48, 47, 55, 0.1);
    }
    &__card-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
    
    }
    &__graph-title h5 {
        margin-bottom: 5px;
        font-size: 16px;
        font-weight: 600;
    }
}

