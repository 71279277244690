@import '../../sass/utils.scss';

.filter-period {
    width: 320px;
}
.filter-period__container {
    display: flex;
}
.filter-period__container .input, select { 
    width: 200px;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.407);
    padding: 0 10px ;
    font-size: 12px;
}
.input-options {
    height: 40px;
}
.filter-period__btn {
    width: 100px;
    height: 30px;
    margin-left: 20px;
    margin-top: 0px;
}