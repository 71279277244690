@import '../../sass/utils.scss';

.service__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    padding: 50px 30px;
    font-family: $title-font;
}
.service__nav p {
    margin: 0 50px 0 0px;
    font-size: 20px; 
    font-weight: 800;
    text-align: center;
    color: $black-color-second;
    cursor: pointer;
}
.service__nav p:hover {
    color: $brand-purple;
    border-bottom: 2px solid $brand-purple;
    
}