@import '../../sass/utils.scss';

button {
    height: 40px;
    width: 100%;
    font-family: $title-font;
    font-size: 12px;
    cursor: pointer;
    color: $white-color-second;
    background-color: $brand-purple;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}

button:hover {
    background-color: $brand-green;
}
