@import '../../sass/utils.scss';

.notification {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    }
    &__container {
        padding: 20px 30px;
    }
    & h4 {
        font-weight: 400;
    }
}