@import '../../sass/utils.scss';

.antibioticos {
    height: 100vh;
    padding-left: 250px;
    font-family: $title-font;
    background-color: #F4F4F4;
    overflow: scroll;
    & h2 {
        padding: 50px 30px 0;
        font-size: 30px;
    }
    & span {
        color: $brand-green;
    };
    & h4 {
        margin-bottom: 30px;
        font-size: 24px;
        font-weight: 600; 
    }
    &__container {
        margin: 0 30px 20px; 
    }
}
